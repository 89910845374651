import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { HallOfFameQuery } from '../../types/graphql'

import {
  Blanket,
  BlanketBackground,
  BlanketTitle,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'

import { parse } from '../../utils/parse'

import * as classes from './hall-of-fame.module.scss'

interface Props {
  data: HallOfFameQuery
}

export default ({ data }: Props) => (
  <Blanket>
    <SEO title={'Hall of Fame'} />

    <BlanketBackground>
      <BlanketTitle>Hall of fame</BlanketTitle>

      <BlanketContent>
        {data.allWordpressPost.nodes.map((node) => (
          <Section
            title={node.title!}
            titleClassName={classes.title}
            cover={
              node.featured_media?.localFile?.childImageSharp
                ?.fluid as FluidObject
            }
          >
            <Container size={'small'}>
              {node.content && parse(node.content)}
            </Container>
          </Section>
        ))}
      </BlanketContent>
    </BlanketBackground>
  </Blanket>
)

export const query = graphql`
  query HallOfFame {
    allWordpressPost(
      filter: { categories: { elemMatch: { wordpress_id: { eq: 44 } } } }
    ) {
      nodes {
        id
        slug
        title
        content
        featured_media {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1080, maxHeight: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
